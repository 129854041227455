import React, { useState } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import FilterItems from "../components/filter-items";
import Tools from "../components/tools";
import Filters from "../components/filters";
import Sorting from "../components/sorting";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const Category = ({ pageContext }) => {
  const { category, categories, tools, forTitle } = pageContext;

  const [sortBy, setSortBy] = useState("abc");

  const name = category.name;
  const description = category.description;

  const integrations =
    tools.length > 0
      ? [
          ...new Set(
            tools
              .map((tool) => tool.integrations)
              .flat()
              .filter(Boolean)
          ),
        ]
      : [];
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);

  const features =
    tools.length > 0
      ? [
          ...new Set(
            tools
              .map((tool) => tool.features)
              .flat()
              .filter(Boolean)
          ),
        ]
      : [];
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const launch = [
    "< 1 year ago",
    "1-3 years ago",
    "3-5 years ago",
    "5+ years ago",
  ];
  const [selectedLaunch, setSelectedLaunch] = useState([]);

  const pricing = ["< $15/m", "$15/m - $30/m", "$30/m - $50/m", "$50/m +"];
  const [selectedPricing, setSelectedPricing] = useState([]);

  const filterTools = (tools, filters) => {
    const filterKeys = Object.keys(filters);
    return tools.filter((item) => {
      return filterKeys.every((key) => {
        if (typeof filters[key] !== "function") return true;
        return filters[key](item[key]);
      });
    });
  };

  const filters = {
    features: (features) => {
      if (selectedFeatures.length > 0) {
        return features.find((feature) => selectedFeatures.includes(feature));
      } else {
        return true;
      }
    },
    integrations: (integrations) => {
      if (selectedIntegrations.length > 0) {
        return integrations.find((integration) =>
          selectedIntegrations.includes(integration)
        );
      } else {
        return true;
      }
    },
    launchDate: (launchDate) => {
      const now = dayjs();
      const years = now.diff(launchDate, "year");
      let ago = [""];
      if (years === 0) {
        ago = ["< 1 year ago"];
      }
      if (years > 0 && years < 4) {
        ago = ["1-3 years ago"];
      }
      if (years > 3 && years < 6) {
        ago = ["3-5 years ago"];
      }
      if (years > 5) {
        ago = ["5+ years ago"];
      }

      if (selectedLaunch.length > 0) {
        return ago.find((launch) => selectedLaunch.includes(launch));
      } else {
        return true;
      }
    },
    lowestPrice: (lowestPrice) => {
      const price = lowestPrice.split("/")[0];
      const pricingType = lowestPrice.split("/")[1];

      let thePrice = [price];
      let thePriceRange = [""];
      if (pricingType === "m") thePrice = price;

      if (pricingType === "y") thePrice = price / 12;

      if (pricingType === "o") thePrice = price / 36;

      if (pricingType === "u") thePrice = price;

      if (thePrice < 15) {
        thePriceRange = ["< $15/m"];
      }

      if (thePrice > 14 && thePrice < 31) {
        thePriceRange = ["$15/m - $30/m"];
      }
      if (thePrice > 30 && thePrice < 51) {
        thePriceRange = ["$30/m - $50/m"];
      }
      if (thePrice > 50) {
        thePriceRange = ["$50/m +"];
      }

      if (selectedPricing.length > 0) {
        return thePriceRange.find((p) => selectedPricing.includes(p));
      } else {
        return true;
      }
    },
  };

  const sortTools = (tools, sortBy) => {
    if (sortBy === "abc") {
      return tools;
    }

    if (sortBy === "new") {
      return tools.sort((a, b) => {
        return new Date(b.launchDate) - new Date(a.launchDate);
      });
    }

    if (sortBy === "proven") {
      return tools.sort((a, b) => {
        return new Date(a.launchDate) - new Date(b.launchDate);
      });
    }

    if (sortBy === "low") {
      return tools.sort((a, b) => {
        const aPrice = a.lowestPrice.split("/")[0];
        const aPricingType = a.lowestPrice.split("/")[1];
        let aThePrice = aPrice;
        if (aPricingType === "m") aThePrice = aPrice;
        if (aPricingType === "y") aThePrice = aPrice / 12;
        if (aPricingType === "o") aThePrice = aPrice / 36;
        if (aPricingType === "u") aThePrice = aPrice;

        const bPrice = b.lowestPrice.split("/")[0];
        const bPricingType = b.lowestPrice.split("/")[1];
        let bThePrice = bPrice;
        if (aPricingType === "m") bThePrice = bPrice;
        if (aPricingType === "y") bThePrice = bPrice / 12;
        if (aPricingType === "o") bThePrice = bPrice / 36;
        if (aPricingType === "u") bThePrice = bPrice;

        return aPrice - bPrice;
      });
    }

    if (sortBy === "high") {
      return tools.sort((a, b) => {
        const aPrice = a.lowestPrice.split("/")[0];
        const aPricingType = a.lowestPrice.split("/")[1];
        let aThePrice = aPrice;
        if (aPricingType === "m") aThePrice = aPrice;
        if (aPricingType === "y") aThePrice = aPrice / 12;
        if (aPricingType === "o") aThePrice = aPrice / 36;
        if (aPricingType === "u") aThePrice = aPrice;

        const bPrice = b.lowestPrice.split("/")[0];
        const bPricingType = b.lowestPrice.split("/")[1];
        let bThePrice = bPrice;
        if (aPricingType === "m") bThePrice = bPrice;
        if (aPricingType === "y") bThePrice = bPrice / 12;
        if (aPricingType === "o") bThePrice = bPrice / 36;
        if (aPricingType === "u") bThePrice = bPrice;

        return bPrice - aPrice;
      });
    } else {
      return tools;
    }
  };

  const filteredTools = filterTools(tools, filters);

  const sortedTools = sortTools(filteredTools, sortBy);

  return (
    <Layout categories={categories}>
      <Seo title={name + forTitle} description={description} />
      <div className="pt-12">
        <h1 className="h3 text-center mb-4">
          {name} {forTitle}
        </h1>
        <p className="p-md max-w-[450px] px-4 text-center mx-auto">
          {description}
        </p>
      </div>

      {/*

      <Filters
      tools={sortedTools}
      category={category}
      categories={categories}
      integrations={integrations}
      integrationsIcon={<svg className='h-5 mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="-2 -5 24 24" width="24" fill="currentColor"><path d="M7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 C8.07359729,0 9.09074462,0.241691045 10,0.673631164 C10.9092554,0.241691046 11.9264027,0 13,0 C16.8659932,0 20,3.13400675 20,7 C20,10.8659932 16.8659932,14 13,14 C11.9264027,14 10.9092554,13.758309 10,13.3263688 C9.09074462,13.758309 8.07359729,14 7,14 Z M7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 C7.34275439,12 7.6774536,11.9655117 8.0008167,11.899816 C6.76314869,10.6372065 6,8.90772473 6,7 C6,5.09227527 6.76314869,3.36279347 8.0008167,2.10018397 C7.6774536,2.0344883 7.34275439,2 7,2 Z M14.0008167,7 C14.0008167,8.90772473 13.237668,10.6372065 12,11.899816 C12.3233631,11.9655117 12.6580623,12 13.0008167,12 C15.7622404,12 18.0008167,9.76142375 18.0008167,7 C18.0008167,4.23857625 15.7622404,2 13.0008167,2 C12.6580623,2 12.3233631,2.0344883 12,2.10018397 C13.237668,3.36279347 14.0008167,5.09227527 14.0008167,7 Z"></path></svg>}
      selectedIntegrations={selectedIntegrations}
      setSelectedIntegrations={setSelectedIntegrations}
      features={features}
      featuresIcon={<svg className='h-5 mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 24 24" width="24" fill="currentColor"><path d="M2 8.654l2.813 2.822 6.332-6.35-2.814-2.823-6.332 6.35zm4.441 8.128l2.11-2.117a.993.993 0 0 1 1.408 0 1 1 0 0 1 0 1.411l-2.11 2.117a1 1 0 0 1 0 1.411L6.44 21.015a.993.993 0 0 1-1.407 0l-1.407-1.41a1 1 0 0 1 0-1.412l1.407-1.411a.993.993 0 0 1 1.407 0zm9.146-6.35l6.331-6.35-1.407-1.412-6.331 6.35c-.777-.78-.912-1.907-.302-2.52L19.406.956c.61-.612 1.735-.477 2.512.303l1.407 1.41c.778.78.913 1.909.302 2.52l-5.528 5.545c-.61.612-1.735.477-2.512-.303zm-.924 3.866L9.738 9.36l-.704.706 4.925 4.939.704-.706zm1.407 1.412l-.704.705 1.759 1.764c.194.195.51.195.703 0a.5.5 0 0 0 0-.705L16.07 15.71zM2.06 5.77a1.5 1.5 0 0 1 .291-1.704l1.407-1.41a1.49 1.49 0 0 1 1.699-.293L6.924.892a1.986 1.986 0 0 1 2.814 0l2.814 2.823a2 2 0 0 1 0 2.822l-1.407 1.411 8.09 8.114a2.5 2.5 0 0 1 0 3.528 2.482 2.482 0 0 1-3.517 0l-8.09-8.114-1.408 1.411c-.777.78-2.037.78-2.814 0L.592 10.065a2 2 0 0 1 0-2.823l1.467-1.47z"></path></svg>}
      selectedFeatures={selectedFeatures}
      setSelectedFeatures={setSelectedFeatures}
      launch={launch}
      launchIcon={<svg className='h-5 mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="-2.5 -2.5 24 24" width="24" fill="currentColor"><path d="M9.808 12.971l.076-1.064.927-.529A11.644 11.644 0 0 0 13.243 9.5c2.64-2.641 3.446-5.746 2.475-6.718-.972-.97-4.077-.166-6.718 2.475-.76.76-1.397 1.59-1.878 2.432l-.53.927-1.063.076a3.973 3.973 0 0 0-2.355.988 10.984 10.984 0 0 1 3.351 2.295c.98.98 1.752 2.117 2.295 3.351a3.973 3.973 0 0 0 .988-2.355zM6.835 15.8a6.687 6.687 0 0 1-.663.771C5 17.743 3.232 18.096.868 17.631c-.464-2.363-.11-4.131 1.06-5.303.248-.246.506-.468.772-.663a8.975 8.975 0 0 0-2.598-.81 5.974 5.974 0 0 1 1.473-2.416 5.977 5.977 0 0 1 3.81-1.741 13.637 13.637 0 0 1 2.2-2.855c3.32-3.32 7.594-4.427 9.547-2.475 1.952 1.953.844 6.227-2.475 9.546a13.637 13.637 0 0 1-2.855 2.2 5.977 5.977 0 0 1-1.741 3.81 5.955 5.955 0 0 1-2.416 1.474 8.975 8.975 0 0 0-.81-2.598zM5.11 13.39a2.6 2.6 0 0 0-.54-.415c-.432.15-.94.48-1.405.944-.219.22-.487.959-.49 1.905.946-.003 1.688-.274 1.905-.49.465-.466.795-.973.944-1.405a2.6 2.6 0 0 0-.414-.54zm7.778-7.78a1 1 0 1 1 1.414-1.413A1 1 0 0 1 12.89 5.61z"></path></svg>}
      selectedLaunch={selectedLaunch}
      setSelectedLaunch={setSelectedLaunch}
      pricing={pricing}
      pricingIcon={<svg className='h-5 mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="24" fill="currentColor"><path d="M9 13v-2a3 3 0 1 1 0-6V4a1 1 0 1 1 2 0v1h.022A2.978 2.978 0 0 1 14 7.978a1 1 0 0 1-2 0A.978.978 0 0 0 11.022 7H11v2a3 3 0 0 1 0 6v1a1 1 0 0 1-2 0v-1h-.051A2.949 2.949 0 0 1 6 12.051a1 1 0 1 1 2 0 .95.95 0 0 0 .949.949H9zm2 0a1 1 0 0 0 0-2v2zM9 7a1 1 0 1 0 0 2V7zm1 13C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path></svg>}
      selectedPricing={selectedPricing}
      setSelectedPricing={setSelectedPricing}
      />
      */}

      <Sorting
        sortBy={sortBy}
        setSortBy={setSortBy}
        integrationsIcon={
          <svg
            className="h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -5 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 C8.07359729,0 9.09074462,0.241691045 10,0.673631164 C10.9092554,0.241691046 11.9264027,0 13,0 C16.8659932,0 20,3.13400675 20,7 C20,10.8659932 16.8659932,14 13,14 C11.9264027,14 10.9092554,13.758309 10,13.3263688 C9.09074462,13.758309 8.07359729,14 7,14 Z M7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 C7.34275439,12 7.6774536,11.9655117 8.0008167,11.899816 C6.76314869,10.6372065 6,8.90772473 6,7 C6,5.09227527 6.76314869,3.36279347 8.0008167,2.10018397 C7.6774536,2.0344883 7.34275439,2 7,2 Z M14.0008167,7 C14.0008167,8.90772473 13.237668,10.6372065 12,11.899816 C12.3233631,11.9655117 12.6580623,12 13.0008167,12 C15.7622404,12 18.0008167,9.76142375 18.0008167,7 C18.0008167,4.23857625 15.7622404,2 13.0008167,2 C12.6580623,2 12.3233631,2.0344883 12,2.10018397 C13.237668,3.36279347 14.0008167,5.09227527 14.0008167,7 Z"></path>
          </svg>
        }
        featuresIcon={
          <svg
            className="h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -1 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M2 8.654l2.813 2.822 6.332-6.35-2.814-2.823-6.332 6.35zm4.441 8.128l2.11-2.117a.993.993 0 0 1 1.408 0 1 1 0 0 1 0 1.411l-2.11 2.117a1 1 0 0 1 0 1.411L6.44 21.015a.993.993 0 0 1-1.407 0l-1.407-1.41a1 1 0 0 1 0-1.412l1.407-1.411a.993.993 0 0 1 1.407 0zm9.146-6.35l6.331-6.35-1.407-1.412-6.331 6.35c-.777-.78-.912-1.907-.302-2.52L19.406.956c.61-.612 1.735-.477 2.512.303l1.407 1.41c.778.78.913 1.909.302 2.52l-5.528 5.545c-.61.612-1.735.477-2.512-.303zm-.924 3.866L9.738 9.36l-.704.706 4.925 4.939.704-.706zm1.407 1.412l-.704.705 1.759 1.764c.194.195.51.195.703 0a.5.5 0 0 0 0-.705L16.07 15.71zM2.06 5.77a1.5 1.5 0 0 1 .291-1.704l1.407-1.41a1.49 1.49 0 0 1 1.699-.293L6.924.892a1.986 1.986 0 0 1 2.814 0l2.814 2.823a2 2 0 0 1 0 2.822l-1.407 1.411 8.09 8.114a2.5 2.5 0 0 1 0 3.528 2.482 2.482 0 0 1-3.517 0l-8.09-8.114-1.408 1.411c-.777.78-2.037.78-2.814 0L.592 10.065a2 2 0 0 1 0-2.823l1.467-1.47z"></path>
          </svg>
        }
        launchIcon={
          <svg
            className="h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2.5 -2.5 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M9.808 12.971l.076-1.064.927-.529A11.644 11.644 0 0 0 13.243 9.5c2.64-2.641 3.446-5.746 2.475-6.718-.972-.97-4.077-.166-6.718 2.475-.76.76-1.397 1.59-1.878 2.432l-.53.927-1.063.076a3.973 3.973 0 0 0-2.355.988 10.984 10.984 0 0 1 3.351 2.295c.98.98 1.752 2.117 2.295 3.351a3.973 3.973 0 0 0 .988-2.355zM6.835 15.8a6.687 6.687 0 0 1-.663.771C5 17.743 3.232 18.096.868 17.631c-.464-2.363-.11-4.131 1.06-5.303.248-.246.506-.468.772-.663a8.975 8.975 0 0 0-2.598-.81 5.974 5.974 0 0 1 1.473-2.416 5.977 5.977 0 0 1 3.81-1.741 13.637 13.637 0 0 1 2.2-2.855c3.32-3.32 7.594-4.427 9.547-2.475 1.952 1.953.844 6.227-2.475 9.546a13.637 13.637 0 0 1-2.855 2.2 5.977 5.977 0 0 1-1.741 3.81 5.955 5.955 0 0 1-2.416 1.474 8.975 8.975 0 0 0-.81-2.598zM5.11 13.39a2.6 2.6 0 0 0-.54-.415c-.432.15-.94.48-1.405.944-.219.22-.487.959-.49 1.905.946-.003 1.688-.274 1.905-.49.465-.466.795-.973.944-1.405a2.6 2.6 0 0 0-.414-.54zm7.778-7.78a1 1 0 1 1 1.414-1.413A1 1 0 0 1 12.89 5.61z"></path>
          </svg>
        }
        pricingIcon={
          <svg
            className="h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -2 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M9 13v-2a3 3 0 1 1 0-6V4a1 1 0 1 1 2 0v1h.022A2.978 2.978 0 0 1 14 7.978a1 1 0 0 1-2 0A.978.978 0 0 0 11.022 7H11v2a3 3 0 0 1 0 6v1a1 1 0 0 1-2 0v-1h-.051A2.949 2.949 0 0 1 6 12.051a1 1 0 1 1 2 0 .95.95 0 0 0 .949.949H9zm2 0a1 1 0 0 0 0-2v2zM9 7a1 1 0 1 0 0 2V7zm1 13C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path>
          </svg>
        }
      />

      <Tools
        tools={sortedTools}
        category={category}
        sortBy={sortBy}
        setSortBy={setSortBy}
        selectedIntegrations={selectedIntegrations}
        integrationsIcon={
          <svg
            className="h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -5 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 C8.07359729,0 9.09074462,0.241691045 10,0.673631164 C10.9092554,0.241691046 11.9264027,0 13,0 C16.8659932,0 20,3.13400675 20,7 C20,10.8659932 16.8659932,14 13,14 C11.9264027,14 10.9092554,13.758309 10,13.3263688 C9.09074462,13.758309 8.07359729,14 7,14 Z M7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 C7.34275439,12 7.6774536,11.9655117 8.0008167,11.899816 C6.76314869,10.6372065 6,8.90772473 6,7 C6,5.09227527 6.76314869,3.36279347 8.0008167,2.10018397 C7.6774536,2.0344883 7.34275439,2 7,2 Z M14.0008167,7 C14.0008167,8.90772473 13.237668,10.6372065 12,11.899816 C12.3233631,11.9655117 12.6580623,12 13.0008167,12 C15.7622404,12 18.0008167,9.76142375 18.0008167,7 C18.0008167,4.23857625 15.7622404,2 13.0008167,2 C12.6580623,2 12.3233631,2.0344883 12,2.10018397 C13.237668,3.36279347 14.0008167,5.09227527 14.0008167,7 Z"></path>
          </svg>
        }
        selectedFeatures={selectedFeatures}
        featuresIcon={
          <svg
            className="h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -1 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M2 8.654l2.813 2.822 6.332-6.35-2.814-2.823-6.332 6.35zm4.441 8.128l2.11-2.117a.993.993 0 0 1 1.408 0 1 1 0 0 1 0 1.411l-2.11 2.117a1 1 0 0 1 0 1.411L6.44 21.015a.993.993 0 0 1-1.407 0l-1.407-1.41a1 1 0 0 1 0-1.412l1.407-1.411a.993.993 0 0 1 1.407 0zm9.146-6.35l6.331-6.35-1.407-1.412-6.331 6.35c-.777-.78-.912-1.907-.302-2.52L19.406.956c.61-.612 1.735-.477 2.512.303l1.407 1.41c.778.78.913 1.909.302 2.52l-5.528 5.545c-.61.612-1.735.477-2.512-.303zm-.924 3.866L9.738 9.36l-.704.706 4.925 4.939.704-.706zm1.407 1.412l-.704.705 1.759 1.764c.194.195.51.195.703 0a.5.5 0 0 0 0-.705L16.07 15.71zM2.06 5.77a1.5 1.5 0 0 1 .291-1.704l1.407-1.41a1.49 1.49 0 0 1 1.699-.293L6.924.892a1.986 1.986 0 0 1 2.814 0l2.814 2.823a2 2 0 0 1 0 2.822l-1.407 1.411 8.09 8.114a2.5 2.5 0 0 1 0 3.528 2.482 2.482 0 0 1-3.517 0l-8.09-8.114-1.408 1.411c-.777.78-2.037.78-2.814 0L.592 10.065a2 2 0 0 1 0-2.823l1.467-1.47z"></path>
          </svg>
        }
        selectedLaunch={selectedLaunch}
        launchIcon={
          <svg
            className="h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2.5 -2.5 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M9.808 12.971l.076-1.064.927-.529A11.644 11.644 0 0 0 13.243 9.5c2.64-2.641 3.446-5.746 2.475-6.718-.972-.97-4.077-.166-6.718 2.475-.76.76-1.397 1.59-1.878 2.432l-.53.927-1.063.076a3.973 3.973 0 0 0-2.355.988 10.984 10.984 0 0 1 3.351 2.295c.98.98 1.752 2.117 2.295 3.351a3.973 3.973 0 0 0 .988-2.355zM6.835 15.8a6.687 6.687 0 0 1-.663.771C5 17.743 3.232 18.096.868 17.631c-.464-2.363-.11-4.131 1.06-5.303.248-.246.506-.468.772-.663a8.975 8.975 0 0 0-2.598-.81 5.974 5.974 0 0 1 1.473-2.416 5.977 5.977 0 0 1 3.81-1.741 13.637 13.637 0 0 1 2.2-2.855c3.32-3.32 7.594-4.427 9.547-2.475 1.952 1.953.844 6.227-2.475 9.546a13.637 13.637 0 0 1-2.855 2.2 5.977 5.977 0 0 1-1.741 3.81 5.955 5.955 0 0 1-2.416 1.474 8.975 8.975 0 0 0-.81-2.598zM5.11 13.39a2.6 2.6 0 0 0-.54-.415c-.432.15-.94.48-1.405.944-.219.22-.487.959-.49 1.905.946-.003 1.688-.274 1.905-.49.465-.466.795-.973.944-1.405a2.6 2.6 0 0 0-.414-.54zm7.778-7.78a1 1 0 1 1 1.414-1.413A1 1 0 0 1 12.89 5.61z"></path>
          </svg>
        }
        selectedPricing={selectedPricing}
        pricingIcon={
          <svg
            className="h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -2 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M9 13v-2a3 3 0 1 1 0-6V4a1 1 0 1 1 2 0v1h.022A2.978 2.978 0 0 1 14 7.978a1 1 0 0 1-2 0A.978.978 0 0 0 11.022 7H11v2a3 3 0 0 1 0 6v1a1 1 0 0 1-2 0v-1h-.051A2.949 2.949 0 0 1 6 12.051a1 1 0 1 1 2 0 .95.95 0 0 0 .949.949H9zm2 0a1 1 0 0 0 0-2v2zM9 7a1 1 0 1 0 0 2V7zm1 13C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path>
          </svg>
        }
      />
    </Layout>
  );
};

export default Category;
