import React from "react";
import Tool from "./tool";

function Sorting({
  integrationsIcon,
  featuresIcon,
  launchIcon,
  pricingIcon,
  sortBy,
  setSortBy,
}) {
  return (
    <div className="max-w-4xl mx-auto pt-16 lg:pt-12 pb-0">
      <div className="flex items-center justify-center">
        <div className="flex items-center justify-center rounded overflow-hidden border-2 border-slate-900">
          <span
            className={`cursor-pointer h6 pl-3 pr-3 py-1 flex items-center justify-center border-r-2 border-slate-900 ${
              sortBy === "abc"
                ? "fill-white text-white bg-slate-900"
                : "bg-white"
            }`}
            onClick={() => setSortBy("abc")}
          >
            ABC
          </span>
          <span
            className={`cursor-pointer h6 pl-1 pr-3 py-1 flex items-center justify-center border-r-2 border-slate-900 ${
              sortBy === "new"
                ? "fill-white text-white bg-slate-900"
                : "bg-white"
            }`}
            onClick={() => setSortBy("new")}
          >
            {launchIcon} New
          </span>
          <span
            className={`cursor-pointer h6 pl-1 pr-3 py-1 flex items-center justify-center border-r-2 border-slate-900 ${
              sortBy === "proven"
                ? "fill-white text-white bg-slate-900"
                : "bg-white"
            }`}
            onClick={() => setSortBy("proven")}
          >
            {launchIcon} Proven
          </span>
          <span
            className={`cursor-pointer h6 pl-1 pr-3 py-1 flex items-center justify-center border-r-2 border-slate-900 ${
              sortBy === "low"
                ? "fill-white text-white bg-slate-900"
                : "bg-white"
            }`}
            onClick={() => setSortBy("low")}
          >
            {pricingIcon} Low
          </span>
          <span
            className={`cursor-pointer h6 pl-1 pr-3 py-1 flex items-center justify-center ${
              sortBy === "high"
                ? "fill-white text-white bg-slate-900"
                : "bg-white"
            }`}
            onClick={() => setSortBy("high")}
          >
            {pricingIcon} High
          </span>
        </div>
      </div>
    </div>
  );
}

export default Sorting;
