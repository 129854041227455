import React from "react";

function FilterItems({
  selectedIntegrations,
  selectedFeatures,
  selectedTimes,
}) {
  const renderFilters = (label, items) => {
    return items.map((item, i) => {
      return (
        <span className="p-sm px-2 py-1 mb-3 block bg-slate-100 mr-4 rounded">
          {item}
        </span>
      );
    });
  };

  return (
    <div className="max-w-2xl mx-auto mb-8 flex flex-wrap">
      {selectedIntegrations.length > 0 &&
        renderFilters("integration", selectedIntegrations)}
      {selectedFeatures.length > 0 &&
        renderFilters("feature", selectedFeatures)}
      {selectedTimes.length > 0 && renderFilters("time", selectedTimes)}
    </div>
  );
}

export default FilterItems;
