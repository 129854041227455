import React from "react";
import Tool from "./tool";

function Tools({
  tools,
  category,
  selectedIntegrations,
  integrationsIcon,
  selectedFeatures,
  featuresIcon,
  selectedLaunch,
  launchIcon,
  selectedPricing,
  pricingIcon,
  sortBy,
  setSortBy,
}) {
  const renderTools = () => {
    return tools.map((tool) => <Tool tool={tool} />);
  };

  return (
    <div className="max-w-xl mx-auto pb-16">
      <div className="">{renderTools()}</div>
    </div>
  );
}

export default Tools;
