import React, { useState } from "react";
import dayjs from "dayjs";
import { Link } from "gatsby";

function Tool({ tool }) {
  const [screenshotModal, setScreenshotModal] = useState(false);
  const [toolOpen, setToolOpen] = useState(false);

  return (
    <Link
      className="block bg-white mt-12 border-2 border-slate-900 rounded p-4 md:p-6 relative mx-4 lg:mx-0 hover:bg-slate-50 cursor-pointer transition-all"
      to={`/${tool.slug}`}
      state={{ modal: true }}
    >
      <h2 className="h4 mb-2 flex items-center justify-start mt-6 md:mt-0">
        <img className="max-h-[30px] max-w-[30px] mr-2" src={tool.logo} />
        {tool.name}
      </h2>
      <p className="p-md mb-6 md:mb-0">{tool.description}</p>
      <div className="absolute bottom-2 right-4 hidden">
        {tool.categories.map((cat, i) => (
          <span className="h6 text-slate-800" key={i}>
            {cat}
          </span>
        ))}
      </div>

      <div className="absolute top-2 right-0 md:top-3 md:right-1 flex items-center justify-start">
        {tool.launchDate && (
          <div className="flex items-center justify-start bg-orange-500 border border-orange-500 text-white fill-white rounded pt-0.5 pb-0.5 pr-1 mr-2">
            <svg
              className="h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-2.5 -2.5 24 24"
              width="24"
              fill="currentColor"
            >
              <path d="M9.808 12.971l.076-1.064.927-.529A11.644 11.644 0 0 0 13.243 9.5c2.64-2.641 3.446-5.746 2.475-6.718-.972-.97-4.077-.166-6.718 2.475-.76.76-1.397 1.59-1.878 2.432l-.53.927-1.063.076a3.973 3.973 0 0 0-2.355.988 10.984 10.984 0 0 1 3.351 2.295c.98.98 1.752 2.117 2.295 3.351a3.973 3.973 0 0 0 .988-2.355zM6.835 15.8a6.687 6.687 0 0 1-.663.771C5 17.743 3.232 18.096.868 17.631c-.464-2.363-.11-4.131 1.06-5.303.248-.246.506-.468.772-.663a8.975 8.975 0 0 0-2.598-.81 5.974 5.974 0 0 1 1.473-2.416 5.977 5.977 0 0 1 3.81-1.741 13.637 13.637 0 0 1 2.2-2.855c3.32-3.32 7.594-4.427 9.547-2.475 1.952 1.953.844 6.227-2.475 9.546a13.637 13.637 0 0 1-2.855 2.2 5.977 5.977 0 0 1-1.741 3.81 5.955 5.955 0 0 1-2.416 1.474 8.975 8.975 0 0 0-.81-2.598zM5.11 13.39a2.6 2.6 0 0 0-.54-.415c-.432.15-.94.48-1.405.944-.219.22-.487.959-.49 1.905.946-.003 1.688-.274 1.905-.49.465-.466.795-.973.944-1.405a2.6 2.6 0 0 0-.414-.54zm7.778-7.78a1 1 0 1 1 1.414-1.413A1 1 0 0 1 12.89 5.61z"></path>
            </svg>
            <span className="p-sm-b text-white -ml-1">
              {dayjs(tool.launchDate).format("YYYY")}
            </span>
          </div>
        )}

        {tool.freeVersion && (
          <div className="flex items-center justify-start border bg-blue-500 border-blue-500 text-white fill-white rounded pt-0.5 pb-0.5 pr-1 mr-2">
            <svg
              className="h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-1.5 -1.5 24 24"
              width="24"
              fill="currentColor"
            >
              <path d="M17.014.872l3.448 3.447a2 2 0 0 1 .463 2.103l-1.763 4.812a1 1 0 0 1-.232.363l-8.323 8.323a2 2 0 0 1-2.829 0l-6.364-6.364a2 2 0 0 1 0-2.828l8.333-8.333a1 1 0 0 1 .364-.232L14.913.408a2 2 0 0 1 2.101.464zm-6.009 3.094l-8.177 8.176 6.364 6.364 8.168-8.167 1.687-4.605L15.6 2.286l-4.595 1.68zm2.784 3.58a1.5 1.5 0 1 1 2.12-2.121 1.5 1.5 0 0 1-2.12 2.12z"></path>
            </svg>
            <span className="p-sm-b text-white -ml-0.5">Free</span>
          </div>
        )}

        <div className="flex items-center justify-start bg-emerald-500 border border-emerald-500 text-white fill-white rounded pt-0.5 pb-0.5 px-1.5 mr-2">
          <svg
            className="h-4 -ml-1 hidden"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -2 24 24"
            width="24"
            fill="currentColor"
          >
            <path d="M9 13v-2a3 3 0 1 1 0-6V4a1 1 0 1 1 2 0v1h.022A2.978 2.978 0 0 1 14 7.978a1 1 0 0 1-2 0A.978.978 0 0 0 11.022 7H11v2a3 3 0 0 1 0 6v1a1 1 0 0 1-2 0v-1h-.051A2.949 2.949 0 0 1 6 12.051a1 1 0 1 1 2 0 .95.95 0 0 0 .949.949H9zm2 0a1 1 0 0 0 0-2v2zM9 7a1 1 0 1 0 0 2V7zm1 13C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"></path>
          </svg>
          {tool.lowestPrice && (
            <span className="p-sm-b text-white">
              ${tool.lowestPrice.split("/")[0]}/
              <span className="">{tool.lowestPrice.split("/")[1]}</span>
            </span>
          )}
        </div>
      </div>
    </Link>
  );
}

export default Tool;
